<template>
  <div class="system-architecture">
    <div class="system-architecture-container">
      <SubTitle :title="title"/>
      <div class="img-container" >
        <img src="@/assets/images/architecture.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from '@/components/SubTitle/index.vue'
export default {
  name: 'SystemArch',
  components: { SubTitle },
  data(){
    return {
      title:'微粒化解耦中台架构，一站式数字化解决方案',
    }
  },
  mounted(){
    this.$bus.$on('getLang', ()=>{
      this.title = this.$t('arch.title')
    })
  }
}
</script>

<style scoped>
.system-architecture {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 80px;
}

.system-architecture .system-architecture-container {
  width: 1383px;
  margin: 0 auto;
}

.system-architecture .system-architecture-container .img-container {
  width: 100%;
  margin-top: 20px;
}

.system-architecture .system-architecture-container .img-container img {
width: 100%;
}
</style>
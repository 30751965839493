<template>
  <div class="solution">
    <div class="solution-container">
      <Title :title="title"></Title>
      <ul >
        <li>
          <div class="image-wraper">
            <img src="@/assets/images/solution-prod01.png" alt="" >
          </div>
          <a href="" :style="{'font-size': font}">{{ $t('solution.wire') }}</a>
        </li>
        <li>
          <div class="image-wraper">
            <img src="@/assets/images/solution-prod02.png" alt="">
          </div>
          <a href="" :style="{'font-size': font}">{{ $t('solution.board') }}</a>
        </li>
        <li>
          <div class="image-wraper">
            <img src="@/assets/images/solution-prod03.png" alt="">
          </div>
          <a href="" :style="{'font-size': font}">{{ $t('solution.pcba') }}</a>
        </li>
        <li>
          <div class="image-wraper">
            <img src="@/assets/images/solution-prod04.png" alt="">
          </div>
          <a href="" :style="{'font-size': font}">{{ $t('solution.metal') }}</a>
        </li>
        <li>
          <div class="image-wraper">
            <img src="@/assets/images/solution-prod05.png" alt="">
          </div>
          <a href="" :style="{'font-size': font}">{{ $t('solution.bar') }}</a>
        </li>
        <li>
          <div class="image-wraper">
            <img src="@/assets/images/solution-prod06.png" alt="">
          </div>
          <a href="" :style="{'font-size': font}">{{ $t('solution.assembly') }}</a>
        </li>
        <li>
          <div class="image-wraper">
            <img src="@/assets/images/solution-prod07.png" alt="">
          </div>
          <a href="" :style="{'font-size': font}">{{ $t('solution.injection') }}</a>
        </li>
        <li>
          <div class="image-wraper">
            <img src="@/assets/images/solution-prod08.png" alt="">
          </div>
          <a href="" :style="{'font-size': font}">{{ $t('solution.materials') }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Title from '@/components/SubTitle/index.vue'
export default {
  name: 'solution',
  components: { Title },
  data() {
    return {
      title: '解决方案',
      font: '16px',
    }
  },
  mounted() {
    this.$bus.$on('getLang', (lang) => {
      this.title = this.$t('solution.title')
      this.font = lang === 'en' ? '15px' : '16px'
    })
  }

}
</script>

<style scoped>
.solution {
  padding-top: 20px;
  padding-bottom: 70px;
}

.solution .solution-container {
  width: 1383px;
  margin: 0 auto;
}

.solution .solution-container ul {
  display: flex;
  margin-top: 20px;
}

.solution .solution-container ul li {
  flex: 1;
  transform: translateY(0);
  transition: all 0.2s;
}

.solution .solution-container ul li .image-wraper {
  width: 100px;
  height: 100px;
  margin: 20px auto 30px;
}

.solution .solution-container ul li .image-wraper img {
  width: 100%;
}

.solution .solution-container ul li a {
  display: inline-block;
  font-size: 16px;
  color: #333;
  width: 100%;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
}


</style>
<template>
  <div class="desciption" id="desciption">
    <div>
      <div class="desc-container">
      <div class="desc-content">
        <!-- <h5>易代工网络科技</h5> -->
        <p>e-Maker Solutions 易代工网络科技成立于2019年，是一家由全球工业隐形冠军企业 \ 工业互联网资深专家共同参与创立、聚焦工业定制领域的产业数字化服务商。</p>
        <p>eMaker 关注客户的挑战与压力，基于数字中台技术，为中小制造业提供适用、好用、实惠的一站式数字化解决方案和配套工业服务，帮助企业通过实施全面数智化管理，实现企业的质量、效率和动力变革，助力成为专精特新小巨人企业，开创事业发展新格局。</p>
      </div>
      <div class="desc-img">
        <div></div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'description',
}
</script>

<style scoped>
.desciption {
  background-image: url('~@/assets/images/description-bg.jpg');
  background-size: cover;
  background-position:  center;
  background-repeat: no-repeat;
}

.desciption .desc-container {
  width: 1383px;
  margin: 0 auto;
  height: 540px;
  display: flex;
  justify-content: space-between;
}

.desciption .desc-container .desc-content {
  flex: 4;
  margin-top: 50px;
}

.desciption .desc-container .desc-content p {
  font-size: 20px;
  line-height: 2.6;
  color: #1e1e1e;
  margin-top: 40px;
}


.desciption .desc-container .desc-img {
  flex:3;
  height: 400px;
  margin-left: 16px;
}

.desciption .desc-container .desc-img > div {
  width: 100%;
  height: 100%;
}
</style>
<template>
    <div 
       class="home"
       v-loading="loading" 
       element-loading-text="切换语言中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(255, 255, 255, 0.5)"
    >
    <!-- 轮播图 -->
    <Carousel></Carousel>
    <!-- 公司描述 -->
    <Description></Description>
    <!-- 数字产品 -->
    <DigitalProduct></DigitalProduct>
    <!-- 工业服务 -->
    <IndustryServe></IndustryServe>
    <!-- 系统架构 -->
    <SystemArch></SystemArch>
    <!-- 服务行业 -->
    <ServiceTrade></ServiceTrade>
    <!-- 解決方案 -->
    <Solution></Solution>
    <!-- 选择我们 -->
    <!-- <Choose></Choose> -->
    <!-- 公司价值 -->
    <CompanyVlue></CompanyVlue>
    <!-- 战略伙伴 -->
    <PaternerBrand></PaternerBrand>
    <!-- 联系我们 -->
    <ContactUs></ContactUs>
  </div>
</template>

<script>
import Carousel from './Carousel/index.vue'
import Description from './Descrition/index.vue'
import DigitalProduct from './DigitalProduct/index.vue'
import IndustryServe from './IndustryServe/index.vue'
import SystemArch from './SystemArch/index.vue'
import ServiceTrade from './ServiceTrade/index.vue'
import Solution from './Solution/index.vue'
import CompanyVlue from './CompanyValue/index.vue'
import PaternerBrand from './PartnerBrand/index.vue'
export default {
  components:{ 
    Description,
    Carousel,
    DigitalProduct,
    IndustryServe,
    SystemArch,
    ServiceTrade,
    Solution,
    CompanyVlue,
    PaternerBrand
  },
  data(){
    return{
      loading: false,
    }
  },
  mounted(){
    this.$bus.$on('getLang', () =>{
      this.loading = true
      setTimeout(()=>{
        this.loading = false
      },500)
    })
  },
  methods:{
  }
}
</script>

<style scoped>
</style>
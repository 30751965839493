<template>
  <div class="paterner-brand" id="paternerBrand">
    <div class="paterner-brand-container">
      <Title :title="title"></Title>
      <div class="swiper-container swiper-wheel swiper-no-swiping" ref="brandSwiper">
        <div class="swiper-wrapper">
          <!-- 滑动单元 -->
          <div class="swiper-slide">
            <div class="img-slide">
            </div>
            <div class="img-slide">
            </div>
            <div class="img-slide">
            </div>
            <div class="img-slide">
            </div>
            <div class="img-slide">
            </div>
            <div class="img-slide">
            </div>
            <div class="img-slide">
            </div>
            <div class="img-slide">
            </div>
            <div class="img-slide">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/SubTitle/index.vue'
import Swiper from "swiper";
export default {
  name: '',
  components: { Title },
  data() {
    return {
      title: '战略合作伙伴',
    }
  },
  mounted() {
    this.$bus.$on('getLang', ()=>{
      this.title = this.$t('partner.title')
    })

    // 轮播
    this.$nextTick(() => {
      new Swiper(this.$refs.brandSwiper, {
        slidesPerView: 'auto', // 设置自定义slide宽度
        height: 80,
        loop: true,
        freeMode: true,
        speed: 10000,
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
        },
      });
    })
  }
}
</script>

<style scoped>
.paterner-brand {
  background-color: rgb(255, 255, 255);
  padding-top: 20px;
  padding-bottom: 40px;
}

.paterner-brand .paterner-brand-container {
  width: 1383px;
  margin: 0 auto;
}
.swiper-wheel .swiper-wrapper {
  -webkit-transition-timing-function: linear !important; /*之前是ease-out*/
  -moz-transition-timing-function: linear !important;
  -ms-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
  display: inline-block;
  white-space: nowrap;
  transform: translate3d(-3240px, 0px, 0px);
}

.swiper-wheel .swiper-slide {
  display: inline-block;
  width: 1420px !important;
  height: 80px;
  padding-right: 10px;
}

.swiper-slide .img-slide:nth-child(1) {
  background-image: url("~@/assets/images/brand-aliyun.png");
}

.swiper-slide .img-slide:nth-child(2) {
  background-image: url("~@/assets/images/brand-phoenix.png");
}

.swiper-slide .img-slide:nth-child(3) {
  background-image: url("~@/assets/images/brand-dingding.png");
}

.swiper-slide .img-slide:nth-child(4) {
  background-image: url("~@/assets/images/brand-wechat.png");
}

.swiper-slide .img-slide:nth-child(5) {
  background-image: url("~@/assets/images/brand-kingdee.png");
}

.swiper-slide .img-slide:nth-child(6) {
  background-image: url("~@/assets/images/brand-sap.png");
}
.swiper-slide .img-slide:nth-child(7) {
  background-image: url("~@/assets/images/brand-solidwork.png");
}

.swiper-slide .img-slide:nth-child(8) {
  background-image: url("~@/assets/images/brand-leads.png");
}

.swiper-slide .img-slide {
  display: inline-block;
  width: 160px;
  padding-right: 20px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

</style>
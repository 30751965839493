<template>
  <div class="digt-product" id="digtProduct" ref="digtProduct">
    <div class="digt-product-container">
      <SubTitle :title="title" />
      <div class="swiper-father">
        <div class="swiper-container digit-swiper" ref="digitSwiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide product-list">
              <li>
                <div class="product-bg"></div>
                <div class="product-text">
                  <div class="product-content">
                    <img src="@/assets/images/digt-prod-icon01.png" />
                    <p>{{ $t('digtProd.ibmp') }}</p>
                  </div>
                  <div class="bg"></div>
                </div>
              </li>
              <li>
                <div class="product-bg"></div>
                <div class="product-text">
                  <div class="product-content">
                    <img src="@/assets/images/digt-prod-icon02.png" />
                    <p>{{ $t('digtProd.cockpit') }}</p>
                  </div>
                </div>
              </li>
              <li>
                <div class="product-bg"></div>
                <div class="product-text">
                  <div class="product-content">
                    <img src="@/assets/images/digt-prod-icon03.png" />
                    <p>{{ $t('digtProd.engineer') }}</p>
                  </div>
                  <div class="bg"></div>
                </div>
              </li>
              <li>
                <div class="product-bg"></div>
                <div class="product-text">
                  <div class="product-content">
                    <img src="@/assets/images/digt-prod-icon04.png" />
                    <p>{{ $t('digtProd.chain') }}</p>
                  </div>
                  <div class="bg"></div>
                </div>
              </li>
              <li>
                <div class="product-bg"></div>
                <div class="product-text">
                  <div class="product-content">
                    <img src="@/assets/images/digt-prod-icon05.png" />
                    <p>{{ $t('digtProd.qms') }}</p>
                  </div>
                  <div class="bg"></div>
                </div>
              </li>
              <li>
                <div class="product-bg"></div>
                <div class="product-text">
                  <div class="product-content">
                    <img src="@/assets/images/digt-prod-icon06.png" />
                    <p>{{ $t('digtProd.okr') }}</p>
                  </div>
                  <div class="bg"></div>
                </div>
              </li>
            </div>
          </div>
        </div>
        <!-- 左右切换箭头 -->
        <div id="swiper-btn-digt">
          <div class="swiper-button-prev digt-prev"></div>
          <div class="swiper-button-next digt-next"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from '@/components/SubTitle/index.vue'
import Swiper from "swiper";
export default {
  name: 'digitalProduct',
  components: { SubTitle },
  data() {
    return {
      title: '数字产品',
    }
  },
  mounted() {
    this.$bus.$on('getLang', () => {
      this.title = this.$t('digtProd.title')
    })

    // 轮播
    this.$nextTick(() => {
      new Swiper(this.$refs.digitSwiper, {
        slidesPerView: 'auto', // 设置自定义slide宽度
        height: 80,
        speed: 300,
        autoplay: false,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: "#swiper-btn-digt .swiper-button-next",
          prevEl: "#swiper-btn-digt .swiper-button-prev",
        },
      });
    })

  }
}
</script>

<style scoped>
.digt-product {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 80px;
}
.digt-product .digt-product-container {
  width: 1383px;
  margin: 0 auto;
  position: relative;
}

.digt-product .digt-product-container .swiper-father {
  position: relative;
}

.digit-swiper .swiper-wrapper .swiper-slide {
  display: inline-block;
  min-width: 2084.5px !important;
  height: 230px;
}

.digt-product .digt-product-container .product-list li + li {
  margin-left: 20px;
}

.digt-product .digt-product-container .product-list li {
  width: 330.75px;
  height: 230px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.digt-product .digt-product-container .product-list li .product-bg {
  padding-bottom: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}


.digt-prev {
  left: -32px !important;
}

.digt-next {
  right: -32px !important;
}

.digt-prev,
.digt-next {
  top: 22px;
  color: #333;
  width: 32px;
  height: 230px;
  background-color: #f5f5f5;
}

.digt-prev::after,
.digt-next::after {
  font-size: 28px;
  color: #bbb;
  transform: scaleX(0.7);
}

.digt-product
  .digt-product-container
  .product-list
  li:nth-child(1)
  .product-bg {
  background-image: url("~@/assets/images/digt-prod-01.jpg");
}
.digt-product
  .digt-product-container
  .product-list
  li:nth-child(2)
  .product-bg {
  background-image: url("~@/assets/images/digt-prod-02.jpg");
}
.digt-product
  .digt-product-container
  .product-list
  li:nth-child(3)
  .product-bg {
  background-image: url("~@/assets/images/digt-prod-03.jpg");
}
.digt-product
  .digt-product-container
  .product-list
  li:nth-child(4)
  .product-bg {
  background-image: url("~@/assets/images/digt-prod-04.jpg");
}
.digt-product
  .digt-product-container
  .product-list
  li:nth-child(5)
  .product-bg {
  background-image: url("~@/assets/images/digt-prod-05.jpg");
}
.digt-product
  .digt-product-container
  .product-list
  li:nth-child(6)
  .product-bg {
  background-image: url("~@/assets/images/digt-prod-06.jpg");
}

.digt-product .digt-product-container .product-list li .product-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.digt-product .digt-product-container .product-list li:hover {
  cursor: pointer;
}

.digt-product
  .digt-product-container
  .product-list
  li
  .product-text
  .product-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top:0;
}


.digt-product
  .digt-product-container
  .product-list
  li
  .product-text
  .product-content
  img {
  position: absolute;
  z-index: 999999;
  top: 4px;
  left: 0;
  right: 0;
  width: 72px;
  height: 72px;
  margin: 0 auto;
  margin-top: 48px;
  opacity: 0;
}

.digt-product digt-product-container .product-list li .product-text .bg {
  position: absolute;
  top: 50%;
  bottom: 0;
  width: 100%;
  background-color: rgba(16, 210, 217, 0.95);
}

.digt-product
  .digt-product-container
  .product-list
  li
  .product-text
  .product-content
  p {
  position: absolute;
  z-index: 1;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
  color: #333;
  bottom: 28px;
  left: 0;
  right: 0;
  opacity: 0;
}


.digt-product .digt-product-container .product-list li:hover .product-text .product-content {
  background-color: rgba(79, 209, 233, 0.75);
  color: #fff;
}

.digt-product .digt-product-container .product-list li:hover .product-text .product-content p {
  color: #fff;
  opacity: 1;
}


.digt-product
  .digt-product-container
  .product-list
  li:hover
  .product-text
  .product-content
  img {
  filter: invert(100%);
  opacity: 1;
}

</style>
<template>
  <div class="industry-services" id="idstryServece">
    <div class="industry-services-container">
      <SubTitle :title="title" />
      <ul class="industry-list">
        <li>
          <div class="industry-bg"></div>
          <div class="industry-text">
            <div class="industry-content">
              <img src="@/assets/images/industry-serve-icon01.png" />
              <p>{{ $t('idstyServe.escort') }}</p>
            </div>
          </div>
        </li>
        <li>
          <div class="industry-bg"></div>
          <div class="industry-text">
            <div class="industry-content">
              <img src="@/assets/images/industry-serve-icon02.png" />
              <p>{{ $t('idstyServe.sharing') }}</p>
            </div>
          </div>
        </li>
        <li>
          <div class="industry-bg"></div>
          <div class="industry-text">
            <div class="industry-content">
              <img src="@/assets/images/industry-serve-icon03.png" />
              <p>{{ $t('idstyServe.factory') }}</p>
            </div>
          </div>
        </li>
        <li>
          <div class="industry-bg"></div>
          <div class="industry-text">
            <div class="industry-content">
              <img src="@/assets/images/industry-serve-icon04.png" />
              <p>{{ $t('idstyServe.implementation') }}</p>
            </div>
          </div>
        </li>
    
      </ul>
    </div>
  </div>
</template>

<script>
import SubTitle from '@/components/SubTitle/index.vue'
export default {
  name: 'IndustryServe',
  components: { SubTitle },
  data(){
    return {
      title:'工业服务',
    }
  },
  mounted(){
    this.$bus.$on('getLang', ()=>{
      this.title = this.$t('idstyServe.title')
    })
  }
}
</script>

<style scoped>
.industry-services {
  padding-top: 20px;
  padding-bottom: 80px;
  background-color: rgb(245, 247, 249);
}
.industry-services .industry-services-container {
  width: 1383px;
  margin: 0 auto;
}

.industry-services .industry-services-container .industry-list {
  display: flex;
  margin-top: 20px;
}

.industry-services .industry-services-container .industry-list li {
  flex: 1;
  position: relative;
  overflow: hidden;
  background-color: #f5f5f5;
}


.industry-services .industry-services-container .industry-list li + li {
  margin-left: 20px;
}

.industry-services .industry-services-container .industry-list li:hover {
  cursor: pointer;
}

.industry-services .industry-services-container .industry-list li .industry-bg {
  padding-bottom: 70%;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
}

.industry-services .industry-services-container .industry-list li:nth-child(1) .industry-bg {
  background-image: url('~@/assets/images/industry-bg-01.jpg');
}

.industry-services .industry-services-container .industry-list li:nth-child(2) .industry-bg {
  background-image: url('~@/assets/images/industry-bg-02.jpg');
}

.industry-services .industry-services-container .industry-list li:nth-child(3) .industry-bg {
  background-image: url('~@/assets/images/industry-bg-03.jpg');
}

.industry-services .industry-services-container .industry-list li:nth-child(4) .industry-bg {
  background-image: url('~@/assets/images/industry-bg-04.jpg');
}

.industry-services .industry-services-container .industry-list li .industry-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  text-align: center;
}

.industry-services .industry-services-container .industry-list li:hover .industry-text {
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15));
}


.industry-services .industry-services-container .industry-list li .industry-text .industry-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.industry-services .industry-services-container .industry-list li:hover .industry-text .industry-content {
  background-color: rgba(79, 209, 233, 0.7);
}


.industry-services .industry-services-container .industry-list li .industry-text .industry-content img {
  width: 72px;
  height: 72px;
  filter: invert(100%);
  margin: 0 auto;
  margin-top: 48px;
  opacity: 0;
}

.industry-services .industry-services-container .industry-list li .industry-text .industry-content p {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  margin-top: 32px;
  opacity: 0;
}

.industry-services .industry-services-container .industry-list li:hover .industry-text .industry-content p  {
  color: #fff;
  opacity: 1;
}

.industry-services .industry-services-container .industry-list li:hover .industry-text .industry-content img {
  filter: invert(100%);
  opacity: 1;
}

</style>
<template>
  <div class="serve-trade">
    <div class="serve-trade-container">
      <Title :title="title"></Title>
      <ul>
        <li>
          <div class="img-wraper">
            <img src="@/assets/images/serve-trade-icon01.png" alt="">
          </div>
          <p>{{ $t('serveTrade.auto') }}</p>
        </li>
        <li>
          <div class="img-wraper">
            <img src="@/assets/images/serve-trade-icon02.png" alt="">
          </div>
          <p>{{ $t('serveTrade.energy') }}</p>
        </li>
        <li>
          <div class="img-wraper">
            <img src="@/assets/images/serve-trade-icon03.png" alt="">
          </div>
          <p>{{ $t('serveTrade.care') }}</p>
        </li>
        <li>
          <div class="img-wraper">
            <img src="@/assets/images/serve-trade-icon04.png" alt="">
          </div>
          <p>{{ $t('serveTrade.communication') }}</p>
        </li>
        <li>
          <div class="img-wraper">
            <img src="@/assets/images/serve-trade-icon05.png" alt="">
          </div>
          <p>{{ $t('serveTrade.car') }}</p>
        </li>
        <li>
          <div class="img-wraper">
            <img src="@/assets/images/serve-trade-icon06.png" alt="">
          </div>
          <p>{{ $t('serveTrade.rail') }}</p>
        </li>
        <li>
          <div class="img-wraper">
            <img src="@/assets/images/serve-trade-icon07.png" alt="">
          </div>
          <p>{{ $t('serveTrade.trasfrom') }}</p>
        </li>
        <li>
          <div class="img-wraper">
            <img src="@/assets/images/serve-trade-icon08.png" alt="">
          </div>
          <p>{{ $t('serveTrade.mind') }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Title from '@/components/SubTitle'
export default {
  name: 'serviceTrade',
  components: { Title },
  data() {
    return {
      title: '服务行业',
    }
  },
  mounted() {
    this.$bus.$on('getLang', () => {
      this.title = this.$t('serveTrade.title')
    })
  }
}
</script>

<style scoped>
.serve-trade {
  background-color: rgb(245, 247, 249);
  padding-top: 20px;
  padding-bottom: 80px;
}

.serve-trade .serve-trade-container {
  width: 1383px;
  margin: 0 auto;

}

.serve-trade .serve-trade-container ul {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.serve-trade .serve-trade-container ul li {
  flex: 1;
}

.serve-trade .serve-trade-container ul li .img-wraper {
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  margin: 10px auto;
}

.serve-trade .serve-trade-container ul li .img-wraper img {
  width: 100%;
}

.serve-trade .serve-trade-container ul li p {
  text-align: center;
  font-size: 15px;
  font-weight: normal;
  color: #444;
}

</style>